import React, { useState } from "react";
import { Grid, Avatar, Typography, Popover } from "@material-ui/core";
import {
  commentIcon,
  dotIcon,
  down,
  downreIcon,
  likedUpvote,
  minus,
  more,
  plus,
  upIcon,
} from "../assets";
import { makeStyles } from "@material-ui/core/styles";
import PostCommentTextField from "./PostCommentTextField.web";
import { Post, State, Comment, PostCommentState, UserDetails } from "../RecruiterProfileController.web";

// Define styles
const useStyles = makeStyles((theme) => ({
  subNestedContainer: {
    "&:before": {
      content: "''",
      borderLeft: "1px solid #D9D9D9",
      position: "absolute",
      left: "52px",
      top: "0",
      bottom: "8px",
    },
  },
  nestedCommentContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "52px",
    marginTop: "15px",
    width: "100%",
    flexDirection: "column",
    "&:last-child": {
      background: "#ffffff",
    },
    "&:after": {
      left: "0",
      content: "''",
      position: "absolute",
      width: "35px",
      height: "1px",
      background: "#D9D9D9",
    },
  },
  commentContainer: {
    width: "100%",
    marginBottom: "30px",
    marginTop: "10px",
    position: "relative",
    "&.subNestedContainer": {
      "&:before": {
        content: "''",
        borderLeft: "1px solid #D9D9D9",
        position: "absolute",
        left: "0",
        top: "0",
        bottom: "8px",
      },
    }
  },
  commentItem: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    borderLeft: ({ comment }: { comment: any }) =>
      comment?.replies?.length > 0 ? "1px solid #D9D9D9" : "0px",
    paddingBottom: "0px",
    top: "-8px",
    width: "100%",
  },
  userInfoContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  username: {
    paddingLeft: "26px",
    position: "relative",
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1F497D",
    cursor: "pointer"
  },
  commentTimeContainer: {
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "lighter",
    color: "#1F497D",
  },
  commentContentContainer: {
    paddingLeft: "38px",
    paddingTop: "3px",
    width: "100%",
  },
  commentText: {
    color: "#17365D",
    fontSize: "14px",
    fontFamily: "Arial",
    paddingRight: "38px",
    width: "100%",
    wordBreak: "break-word" as 'break-word' | undefined,
    whiteSpace: 'pre-wrap',
  },
  footerText: {
    color: "#17365D",
    fontSize: "14px",
    fontFamily: "Arial",
  },
  loadMore: {
    color: "#1F497D",
    fontSize: "12px",
    fontFamily: "Arial",
    display: "flex",
    alignItems: "center",
  },
  rootPopover: {
    "& .MuiPopover-paper": {
      border: "2px solid #CBD5E1",
      boxShadow: "none",
      marginTop: "8px",
      borderRadius: "8px"
    }
  },
  itemText: {
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "lighter",
    color: "#1F497D",
    padding: "9px 16px",
    margin: "3px",
    cursor: "pointer",
    "&:hover": {
      background: "#6D97C133",
      borderRadius: "8px",
    }
  },
  deleteItemText: {
    fontFamily: "Arial",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#DC2626",
    padding: "9px 16px",
    margin: "3px",
    cursor: "pointer",
    "&:hover": {
      background: "#6D97C133",
      borderRadius: "8px",
    }
  },

  gridStyles1: {
    borderLeft: ({ comment }: { comment: any }) =>
      comment?.replies?.length >0? " ": '0px',
  }

}));

type Props = {
  handleNameClick: (accountId: string | number) => void;
  getInitials: (value?: string) => string;
  userDetails: UserDetails;
  comment: Comment;
  isNested?: boolean;
  lastChild: boolean;
  parentlength: number;
  index: number;
  createComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string, activityType: string) => void,
  // state: PostCommentState,
  post: Post,
  downvotePostComment: (id: number | string, type: string, postId?: string | number) => void,
  upvotePostComment: (id: number | string, type: string, postId?: string | number) => void,
  activityType: string;
  loader: boolean;
  progress: number;
  remainingTime: number;
  handleDeleteComment: (id: string | number) => void;
  updateComment: (comment: string, files: File[], nested: boolean, cID: number | string, postID: number | string) => void;
};

const CommentComponent = ({
  handleNameClick,
  getInitials,
  userDetails,
  comment,
  isNested = false,
  lastChild,
  parentlength,
  index,
  createComment,
  post,
  // state,
  downvotePostComment,
  upvotePostComment,
  activityType,
  updateComment,
  loader,
  progress,
  remainingTime,
  handleDeleteComment
}: Props) => {
  const classes = useStyles({ comment });

  const [showNestedComments, setShowNestedComments] = useState<boolean>(false);
  const [showtextField, setShowTextField] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [expandedComment, setExpandedComment] = useState<boolean>(false);

  const toggleNestedComments = () => {
    setShowNestedComments(!showNestedComments);
  };
  const openNestedCommetns = () => {
    setShowNestedComments(true);
  };
  let commentsLength = comment?.replies?.length;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleEditMode = (value: boolean) => setEditMode(value);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const truncateText = (comment: string, maxLength: number) => {
    if (comment.length <= maxLength) return comment;
    return comment.slice(0, maxLength);
  }

  const renderComment = () => {
    return expandedComment ? comment.comment : truncateText(comment.comment, 250)
  }

  const toggleReadMore = () => {
    setExpandedComment(!expandedComment)
  }

  return (
    <Grid
        className={getClassName(isNested, commentsLength, classes.nestedCommentContainer, classes.subNestedContainer, classes.commentContainer)}
        item
        >
      <Grid
        className={
          `${getClassName0(isNested,lastChild,showNestedComments,commentsLength)} ${classes.gridStyles1}`
        }
        style={{
          display: "flex",
          position: "relative",
          justifyContent: "space-between",
          borderLeft: commentsLength > 0 ? "" : "0px",
          paddingBottom: "0px",
          top: "-8px",
          width: "100%",
        }}
      >
        <div
          style={{ position: "absolute", top: "-6px", left: "-17px" }}
          className={isNested ? "hryvi0fhg " : ""}
        >
          <Avatar
            style={{ height: "29px", width: "29px", border: '2px solid #1F497D' }}
            src={comment.account.account_profile}
          >
          </Avatar>
        </div>
        <div
          className={ 
            getClassName3(showNestedComments,isNested,lastChild,index)}
          style={{
            position: "absolute",
            cursor: "pointer",
            bottom: "-5px",
            left: "-6px",
          }}
        >
          {commentsLength > 0 && showNestedComments && (
            <img
              src={minus}
              onClick={() => toggleNestedComments()}
              id="minus-btn"
              style={{ background: "#ffffff" }}
            />
          )}
        </div>
        {commentsLength > 0 && !showNestedComments && (
          <div
            style={{
              position: "absolute",
              bottom: "-10px",
              left: "67px",
              cursor: "pointer",
            }}
            className="fhryvjierwe45"
          >
            {
              <Typography
                className={classes.loadMore}
                onClick={() => toggleNestedComments()}
                id="plus-btn-text"
              >
                <img
                  src={plus}
                  alt="plus button"
                  id="plus-btn"
                />
                &nbsp; {commentsLength} more replies
              </Typography>
            }
          </div>
        )}
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "8px",
          }}
        >
          <Grid container item className={classes.userInfoContainer}>
            <Grid item style={{ position: "relative" }}>
              <span className={classes.username} style={{ paddingLeft: !isNested ? "34px" : "26px" }} 
              onClick={() =>
                handleNameClick(comment.account_id)
              }>
                {comment.account.account_name}
              </span>
            </Grid>
            <Grid item>
              <img src={dotIcon} />
              &nbsp;
              <span className={classes.commentTimeContainer}>
                {comment.created_at}
              </span>
              &nbsp; &nbsp;
              {
                userDetails.account_details && ((userDetails.account_details.id === comment.account_id)) && 
                <span data-test-id="moreIcon" onClick={handleClick} style={{ cursor: "pointer", padding: "5px" }}>
                  <img src={more} />
                </span>
              }
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                className={classes.rootPopover}
              >
                <Typography data-test-id='openEditMode' className={classes.itemText} onClick={() => {
                  setEditMode(!editMode)
                  handleClose()
                }}>Edit</Typography>
                <Typography
                  data-test-id='openDeleteMode'
                  className={classes.deleteItemText}
                  onClick={() => {
                    handleDeleteComment(comment.id)
                    handleClose()
                  }}>Delete</Typography>
              </Popover>            
              </Grid>
          </Grid>
          <Grid container item className={classes.commentContentContainer}>
            <Typography className={classes.commentText}>
              {!editMode ?
                renderComment() :
                <PostCommentTextField
                  loader={loader}
                  progress={progress}
                  remainingTime={remainingTime}
                  handleEditMode={handleEditMode}
                  updateComment={updateComment}
                  createComment={createComment}
                  post={post}
                  cmt={comment}
                  isNested={true}
                  openNestedCommetns={openNestedCommetns}
                  activityType={activityType}
                  isEditMode={true} 
                  getInitials={getInitials} 
                  userDetails={userDetails}                
                />}
              <span data-test-id="moreText" className="moreText" style={{cursor: "pointer", color: "rgba(0,0,0,.6)"}} onClick={() => toggleReadMore()}>{!expandedComment && comment.comment.length > 250 && `...more` }</span>
            </Typography>
          </Grid>

          {comment.comment_images && !editMode && <Grid container item className={classes.commentContentContainer}>
            {
              comment.comment_images.map((item) => {
                return (<img src={item.url} style={{ height: "90px", width: "90px", padding: "10px", borderRadius: "16px" }} />)
              })
            }
          </Grid>}
          <Grid item container style={{ padding: "2px 37px" }}>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              id="upvote"
              onClick={() => upvotePostComment(comment.id, "BxBlockComments::Comment", post.id)}
            >
              <img style={{width: '12.75px', height: '12.75px'}} src={comment.isliked ? likedUpvote : upIcon} />
              &nbsp;
              <Typography className={classes.footerText}>
                {comment.likes_count}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "5px",
                cursor: "pointer",
              }}
              id="downvote"
              onClick={() => downvotePostComment(comment.id, "BxBlockComments::Comment", post.id)}
            >
              <img style={{width: '12.75px', height: '12.75px'}} src={comment.isdisliked ? downreIcon : down} />
              &nbsp;
              <Typography className={classes.footerText}>{comment.dislikes_count}</Typography>
            </Grid>
            <Grid
              item
              id="replyIcon"
              style={{
                paddingLeft: "33px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setShowTextField(!showtextField)}
            >
              <img src={commentIcon} />
              &nbsp;
              <Typography
                className={classes.footerText}
              >
                Reply
              </Typography>
            </Grid>
          </Grid>
          {showtextField && (
            <Grid item container style={{ padding: "2px 37px" }}>
              <PostCommentTextField
                loader={loader}
                progress={progress}
                remainingTime={remainingTime} 
                handleEditMode={handleEditMode}
                updateComment={updateComment}
                getInitials={getInitials}
                userDetails={userDetails}
                createComment={createComment}
                post={post}
                cmt={comment}
                isNested={true}
                openNestedCommetns={openNestedCommetns}
                activityType={activityType}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <div style={{ position: "relative" }}>
        {showNestedComments &&
          commentsLength > 0 &&
          comment.replies.map((nestedComment: any, index: number) => (
            <CommentComponent
              getInitials={getInitials}
              userDetails={userDetails}
              key={index}
              index={1}
              comment={nestedComment}
              isNested={true}
              lastChild={comment.replies.length - 1 === index}
              parentlength={comment.replies.length}
              post={post}
              createComment={createComment}
              upvotePostComment={upvotePostComment}
              downvotePostComment={downvotePostComment}
              activityType={activityType}
              loader={false}
              progress={0}
              remainingTime={0}
              handleDeleteComment={handleDeleteComment} 
              updateComment={updateComment}            
              handleNameClick={handleNameClick}
            />
          ))}
      </div>
    </Grid>
  );
};

const getClassName = (isNested: boolean, commentsLength: number, nestedCommentContainer: string, subNestedContainer: string, commentContainer: string) => {
  if (isNested){ 
    return (commentsLength > 0) ? `${nestedCommentContainer} ${subNestedContainer}` : nestedCommentContainer;
  }
  return (commentsLength > 0) ? `${commentContainer} subNestedContainer` : commentContainer;
};

const getClassName0 = (isNested: boolean, lastChild: boolean, showNestedComments: boolean, commentsLength: number) => {
  if (isNested && !lastChild && !showNestedComments && commentsLength > 0) {
    return "moredetails";
  }
  return "";
};

const getClassName3 = (showNestedComments: boolean, isNested: boolean, lastChild: boolean, index: number) => {
  if (isNested){
    if (!showNestedComments && !lastChild ) { return "hfyrufnv14" } 
    else if (index === 1 && showNestedComments) { return "hfyrufnv145" }
  }
  return "";  
};

export default CommentComponent;
